import React, { Component } from 'react';
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import CountrySelect from "../../../Event registration/Steps/CountrySelect"
import {
  getEventPaymentResAction,
  getEventPaymentUrlAction,
  paypalEventAction,
} from '../../../utils/actions/payment.action';
import AlertMsg from '../../../utils/Alert';
import Loader from '../../../user/Loader';
import PopupWindow from '../../../common/PopupWindow';
import styles from './style';
import PaypalPaymentGeneric from '../../../common/PaypalPaymentGeneric';
import { Spinner } from '../../atoms';
import Axios from 'axios';
import { baseURL } from '../../../utils/axios';
import { MarkRequired } from '../../../Event registration/Steps/style.css';
import AddressView from '../../../admin/components/common/ClinicAddresses/AddressView';

class PaymentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNext: false,
      ipAddress: '',
      isUrlGet: false,
      urlName: '',
      urlLink: '',
      loading: false,
      contact: {
        firstName: '',
        lastName: '',
        phoneNumber: '971',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      }
    };

    this.onSuccessfulPayment = this.onSuccessfulPayment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateAddress = this.handleCreateAddress.bind(this);
  }

  componentDidMount = async () => {
    window.addEventListener('beforeunload', () => {
      this.handleClosePortal();
    });
    await await Axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        console.log(res.data.IPv4)
        this.setState({ ipAddress: res.data.IPv4 })
      })
      .catch(e => {
        console.log(e)
      });
    this.setState({
      isNext: false,
      urlName: '',
      isUrlGet: false,
      urlLink: '',
    });
  };

  handleClosePortal = () => {
    clearInterval(this.checkStatus);
    this.setState({
      isUrlGet: false,
      urlLink: '',
      isNext: false,
    });
  };

  componentDidUpdate(preProps) {
    if (this.props.getResponse !== preProps.getResponse) {
      const { status } = this.props.getResponse;
      if (status !== 'unpaid') {
        if (status === 'paid') {
          clearInterval(this.checkStatus);
          AlertMsg('success', 'Payment Successfull!');
          this.setState({ isUrlGet: false, isNext: false });
          this.props.onSubmit({
            ...this.props.getResponse,
            paymentId: this.props.getResponse._id,
          });
        }
        if (status === 'failed') {
          clearInterval(this.checkStatus);
          AlertMsg('error', 'Payment Failed, Please try again!');
          this.setState({ isUrlGet: false, isNext: false });
          this.props.onCancel();
        }
      }
    }

    if (this.state.isUrlGet) {
      const newPath = this.state.urlLink;
      const newWindow = window.open(newPath, "_self");

      document.addEventListener("DOMContentLoaded", function () {
        // Your DOM should be fully loaded here
        newWindow.document
          .querySelector("*** SOME IDENTIFIER ***")
          .click();
      });
    }

    if (this.props.getUrl !== preProps.getUrl) {
      if (this.props.getUrl.getPaymentUrlData) {
        console.log(
          'payment before verification',
          this.props.getUrl.getPaymentUrlData
        );
        this.setState({
          urlLink: this.props.getUrl.getPaymentUrlData.url,
          isUrlGet: true,
          paymentId: this.props.getUrl.getPaymentUrlData.paymentId,
        });
        this.checkStatus = setInterval(() => {
          this.props.getEventPaymentResAction(
            this.props.getUrl.getPaymentUrlData.entityId
          );
        }, 2000);
      }
    }

    if (this.props.paypalData !== preProps.paypalData) {
      if (this.props.paypalData.metaData) {
        const data = this.props.paypalData.metaData.links;
        this.setState({
          urlLink: data[1].href,
          isUrlGet: true,
        });
        this.checkStatus = setInterval(() => {
          this.props.getEventPaymentResAction(this.props.paypalData.billingId);
        }, 2000);
      }
    }
  }

  componentWillUnmount() {
    this.handleClosePortal();
    clearInterval(this.checkStatus);
  }

  handleSubmit = async (e) => {
    const { urlName } = this.state;
    const {
      getEventPaymentUrlAction,
      paypalEventAction,
      entityId,
      entity,
      amount,
      validateBillingAddress,
      billingAddress,
    } = this.props;
    let proceed = true;
    console.log("asdasdas d asd sa ")
    if (validateBillingAddress) {
      proceed = validateBillingAddress();
    }

    if (!proceed) {
      return;
    }

    if (urlName === 'credit card') {
      this.setState({ loading: true })
      console.log("asdas das das d asd as dsa dasd sa d")
      await getEventPaymentUrlAction(entityId, {
        publicIp: this.state.ipAddress,
        amount,
        entity,
        attendies: this.props.attendies,
        billingAddress,
      });
      this.setState({ isNext: true });
    } else if (urlName === 'paypal') {
      await paypalEventAction(entityId, {
        publicIp: this.state.ipAddress,
        entity,
      });
      this.setState({ isNext: true });
    }

    if (!urlName) {
      AlertMsg('error', 'Please select a payment method');
    }
  };

  onSuccessfulPayment(data) {
    console.log(data, "sadsad asd asd asd ")
    const {
      entityId,
      entity,
      amount,
    } = this.props;
    let postData = {
      ...data,
      entityId,
      amount,
      entity,
      attendies: this.props.attendies,
    }

    postData.status = postData.status == "success" ? "paid" : "unpaid"
    if (this.props.onSubmit) {
      this.props.onSubmit(postData);
    }
  }

  handleChange(e) {
    console.log(e.target)
    let newContact = {
      ...this.state.contact,
      [e.target.name]: e.target.value,
    }
    this.setState({
      contact: newContact
    });
    console.log(newContact)
    if (this.props.handleAddressChange) {
      this.props.handleAddressChange(newContact)
    }
  };

  handleCreateAddress(address) {
    console.log(address)
    let newContact = {
      ...this.state.contact,
      address1: address.address1,
      address2: address.address2,
      state: address.state,
      city: address.city,
      zip: address.zip,
      country: address.country,
    }
    this.setState({
      contact: newContact
    });

    if (this.props.handleAddressChange) {
      this.props.handleAddressChange(newContact)
    }
  };

  render() {
    const { classes, onCancel } = this.props;
    return (
      <div>
        {this.state.loading && <Spinner isOpen />}
        <fieldset>
          <h3 className="text-lg font-bold mb-2">Select Payment Method</h3>
          <div className="bg-white rounded-md -space-y-px">
            { }
            <label className="relative border p-4 flex cursor-pointer focus:outline-none">
              <input
                type="radio"
                name="checkout"
                onChange={e => {
                  this.props.handlePaymentSelection(e.target.value)
                  this.setState({ urlName: e.target.value })
                }}
                defaultValue="credit card"
                className="payment-method h-4 w-4 mt-0.5 cursor-pointer text-brand-blue border-gray-300 focus:ring-brand-blue"
                aria-labelledby="checkout-0-label"
                aria-describedby="checkout-0-description"
              />
              <div className="ml-3 flex flex-col">
                { }
                <span id="checkout-0-label" className="block text-sm font-bold">
                  Credit Card
                </span>
                { }
                <span
                  id="checkout-0-description"
                  className="block text-sm text-gray-400"
                >
                  Visa, Mastercard, Amex. Payment will be processed by PayTabs.
                </span>
              </div>
            </label>
            { }
            <label className="relative border p-4 flex cursor-pointer focus:outline-none">
              <input
                type="radio"
                name="checkout"
                onChange={e => {
                  this.props.handlePaymentSelection(e.target.value)
                  this.setState({ urlName: e.target.value })
                }}
                defaultValue="paypal"
                className="payment-method h-4 w-4 mt-0.5 cursor-pointer text-brand-blue border-gray-300 focus:ring-brand-blue"
                aria-labelledby="checkout-1-label"
                aria-describedby="checkout-1-description"
              />
              <div className="ml-3 flex flex-col">
                { }
                <span id="checkout-1-label" className="block text-sm font-bold">
                  PayPal
                </span>
                { }
                <span
                  id="checkout-1-description"
                  className="block text-sm text-gray-400"
                >
                  Use your PayPal account to make this payment.
                </span>
              </div>
            </label>
          </div>
        </fieldset>
        {this.state.urlName === "credit card" && (
          <div className="pt-4">
            <Typography className="py-8">
              Billing Information*
            </Typography>

            <div className='grid grid-cols-2 gap-2'>
              <div className='w-full'> <Typography className={classes.inputTitle}>
                First Name*
              </Typography>
                <input
                  value={this.state.contact.firstName}
                  onChange={this.handleChange}
                  type='text'
                  name='firstName'
                  className='w-full'
                /></div>
              <div className='w-full'><Typography className={classes.inputTitle}>
                Last Name*
              </Typography>
                <input
                  value={this.state.contact.lastName}
                  type='text'
                  onChange={this.handleChange}
                  name='lastName'
                  className='w-full'
                /></div>
            </div>

            <div className='w-full'>
              <Typography >
                Phone Number*
              </Typography>
              <input
                value={this.state.contact.phoneNumber}
                type='number'
                onChange={this.handleChange}
                name='phoneNumber'
                className='w-full'
              />
            </div>

            <Grid item xs={12} style={{ marginBottom: '15px' }}>
              <AddressView
                handleChange={this.handleCreateAddress}
                editingAddress={{
                  address1: this.state.contact.address1,
                  address2: this.state.contact.address2,
                  state: this.state.contact.state,
                  city: this.state.contact.city,
                  country: this.state.contact.country,
                  zip: this.state.contact.zip,
                }}
              />
            </Grid>
          </div>
        )}
        {/* {this.state.urlName === 'paypal' && (
          <div
            className="w-full pt-4 content-center"
          >
            <PaypalPaymentGeneric
              amount={this.props.amount}
              onSuccessfulPayment={this.onSuccessfulPayment}
            />
          </div>
        )}
        <Grid container justify='center' style={{ marginTop: 25 }}>
          <CustomCancelButtons
            title='Cancel'
            textStyles={classes.cancelText}
            style={classes.cancelButton}
            onPressButton={() => onCancel()}
          />
          {this.state.isNext ? (
            <Loader />
          ) : (
            this.state.urlName !== 'paypal' && (
              <CustomNextButtons
                title='Next'
                style={classes.nextButton}
                onPressButton={this.handleSubmit}
              />
            )
          )}
        </Grid> */}
      </div>
    );
  }
}

PaymentOption.defaultProps = {
  getResponse: {},
  getUrl: {},
  getAppointmentData: {},
  appointmentLoader: true,
};

const connected = connect(
  (state) => ({
    getUrl: state.getPaymentUrl,
    getResponse: state.getPaymentRes.getPaymentResData,
    getAppointmentData: state.getAppointmentDetail.getAppointmentDetailData,
    appointmentLoader: state.getAppointmentDetail.request,
    paypalData: state.paypal.paypalData,
    eCheckData: state.eCheck.eCheckData,
  }),
  {
    getEventPaymentUrlAction,
    paypalEventAction,
  }
)(PaymentOption);

const formed = reduxForm({
  form: 'payment',
})(connected);

export default withStyles(styles)(formed);
